"use client";

import React from "react";
import { useSearchParams } from "next/navigation";
import { useQuery } from "@tanstack/react-query";
import { compact } from "lodash";

import { QUERY_KEYS, MUTATION_KEYS } from "../../constants";
import api from "../../api";
import LayoutRenderer, { FETCHERS as LAYOUT_FETCHERS } from "../LayoutRenderer";
import BugsnagClient from "../../utils/bugsnag";

const fetcher = api();

function FallbackLayout() {
  const { data: layout, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.FALLBACK_LAYOUT],
    queryFn: fetcher.getFallbackLayout,
    retry: false,
    staleTime: Infinity,
  });

  if (isLoading) {
    return null;
  }

  return <LayoutRenderer layout={layout} />;
}

class FallbackLayoutErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error) {
    BugsnagClient.notify(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <FallbackLayout />;
    }

    return children;
  }
}

export default function HomeLayout() {
  const searchParams = useSearchParams();
  const layoutId = searchParams.get("custom-home-page-layout-id");

  const { data: layout } = useQuery({
    queryKey: compact([QUERY_KEYS.LAYOUT, layoutId]),
    queryFn: () => fetcher.getLayout(layoutId),
  });

  return (
    <FallbackLayoutErrorBoundary>
      <LayoutRenderer
        layout={layout}
        mutationToQueryInvalidationMap={{
          [MUTATION_KEYS.UPDATE_FAVORITE]: [LAYOUT_FETCHERS.FAVORITES_SHELF],
        }}
      />
    </FallbackLayoutErrorBoundary>
  );
}
